<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card>
      <v-card-title>
        <menu-title title="Reset Password"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Old Password"
              v-model="oldPassword"
              :append-icon="oldPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
              :type="oldPasswordShown ? 'text' : 'password'"
              hide-details="auto"
              @click:append="oldPasswordShown = !oldPasswordShown"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="New Password"
              v-model="newPassword"
              :rules="[
                rules.required,
                rules.minLength,
                rules.minNumber,
                rules.minUpperCase,
              ]"
              :append-icon="newPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
              :type="newPasswordShown ? 'text' : 'password'"
              hide-details="auto"
              @click:append="newPasswordShown = !newPasswordShown"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Repeat new password"
              v-model="newPasswordRepeat"
              :rules="[matchesPassword]"
              :append-icon="newPasswordRepeatShown ? 'mdi-eye' : 'mdi-eye-off'"
              :type="newPasswordRepeatShown ? 'text' : 'password'"
              hide-details="auto"
              @click:append="newPasswordRepeatShown = !newPasswordRepeatShown"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="$emit('done')">
          <span>Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined @click="changePassword()">
          <span>Change</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
export default {
  name: "passwordResetDialog",
  components: {
    menuTitle,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters({ newPasswordReset: "newPasswordReset" }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    tmpNewPasswordReset: {},

    oldPassword: "",
    oldPasswordShown: false,
    newPassword: "",
    newPasswordShown: false,
    newPasswordRepeat: "",
    newPasswordRepeatShown: false,

    rules: {
      required: (x) => !!x || "Cannot be empty",
      minLength: (x) => x.length >= 10 || "Must be at least 10 characters",
      minNumber: (x) => /[0-9]/.test(x) || "Must contain at least one number",
      minUpperCase: (x) =>
        /[A-Z]/.test(x) || "Must contain at least one upper case character",
    },
  }),
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
      patchPassword: "patchPassword",
    }),
    matchesPassword: function (pw) {
      return pw == this.newPassword ?? "Passwords do not match";
    },
    changePassword: function () {
      if (this.newPassword != this.newPasswordRepeat) {
        this.showSnackbar("Passwords do not match");
      } else {
        this.patchPassword({
          password: this.oldPassword,
          newPassword: this.newPassword,
        }).then((err) => {
          if (err) {
            this.showSnackbar("Could not update Password");
          } else {
            this.showSnackbar("Password updated");
            this.$emit("done");
          }
        });
      }
    },
  },
  async mounted() {
    this.tmpNewPasswordReset = JSON.parse(
      JSON.stringify(this.newPasswordReset)
    );
  },
};
</script>

<style>
</style>