<template>
  <v-card>
    <v-card-title>
      <menu-title title="Profile Settings"></menu-title>
    </v-card-title>
    <v-card-text>
      <v-subheader>Profile Details</v-subheader>
      <v-row>
        <v-col cols="4">
          <v-text-field
            label="Username"
            v-model="tmpProfile.username"
            outlined
            hide-details
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Forename"
            v-model="tmpProfile.contact"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Surname"
            v-model="tmpProfile.contactname"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Email"
            v-model="tmpProfile.email"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Phone"
            v-model="tmpProfile.tel"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-subheader>Company Details</v-subheader>
      <v-row>
        <v-col cols="2">
          <v-text-field
            label="Customer ID"
            v-model="tmpProfile.customerid"
            outlined
            hide-details
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="10">
          <v-text-field
            label="Company Name"
            v-model="tmpProfile.name"
            outlined
            hide-details
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            label="Country"
            v-model="tmpProfile.country"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="State / Province"
            v-model="tmpProfile.state"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            label="Zip / Postal"
            v-model="tmpProfile.zip"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="City"
            v-model="tmpProfile.city"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Street"
            v-model="tmpProfile.street"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="House Number"
            v-model="tmpProfile.hnumber"
            outlined
            hide-details
            :disabled="!editMode"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        outlined
        class="ma-3"
        @click="
          backup();
          editMode = !editMode;
        "
      >
        <span v-if="editMode">Cancel</span>
        <span v-else>Edit</span>
      </v-btn>
      <v-btn v-if="editMode" outlined class="ma-3" @click="saveChanges">
        <span>Save changes</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined class="ma-3" @click="passwordResetDialog = true">
        <span>Reset password</span>
      </v-btn>
    </v-card-actions>

    <password-reset-dialog
      v-if="passwordResetDialog"
      v-model="passwordResetDialog"
      @done="passwordResetDialog = false"
    ></password-reset-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "../../components/helpers/menu_title.vue";
import passwordResetDialog from "../../components/profile_password_reset.vue";
export default {
  name: "Profile",
  components: {
    menuTitle,
    passwordResetDialog,
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
  data: () => ({
    editMode: false,
    passwordResetDialog: false,
    tmpProfile: {},
    backupProfile: {},
  }),
  methods: {
    ...mapActions({
      patchProfile: "patchProfile",
      showSnackbar: "showSnackbar",
    }),
    backup: function () {
      if (this.editMode) {
        this.tmpProfile = JSON.parse(JSON.stringify(this.backupProfile));
      } else {
        this.backupProfile = JSON.parse(JSON.stringify(this.profile));
      }
    },
    saveChanges: function () {
      this.tmpProfile["customerid"] = this.tmpProfile["customerid"] ?? 0;
      this.tmpProfile["userid"] = this.tmpProfile["userid"] ?? 0;
      Object.keys(this.tmpProfile)
        .filter((k) => !["customerid", "userid"].includes(k))
        .forEach((k) => (this.tmpProfile[k] = this.tmpProfile[k] ?? ""));
      console.log(JSON.parse(JSON.stringify(this.tmpProfile)));
      this.patchProfile(this.tmpProfile).then((error) => {
        if (error) {
          this.showSnackbar(
            "Profile could not be updated. Error: {0}".format(error)
          );
        } else {
          this.showSnackbar("Profile has been updated");
          this.editMode = !this.editMode;
        }
      });
    },
  },
  async mounted() {
    this.tmpProfile = JSON.parse(JSON.stringify(this.profile));
  },
};
</script>